.modal-form
  span.modal-form-legend
    display: block
    margin-bottom: $margin / 2

    strong
      color: $red

  .searchable-dropdown-wrapper
    margin-bottom: 20px

    input
      width: 100%
      border-radius: 4px
      padding: 10px 5px

  label > span
    margin-left: 2px

    &.optional, &.optional-field
      color: silver
      font-size: .75em

    &.mandatory-field
      color: $red

  .modal-form-common-fields
    & > div
      padding: 0

.inline-field
  display: flex
  align-items: center

  label
    margin: 0

.hidden
  display: none

.visible
  display: block

.orders-container
  input[type=checkbox].form-control
    width: 15px
    height: 15px

.offerings-form
  width: 100%
