.searchable-dropdown-wrapper
  width: 100%

  label > span
    margin-left: 5px

.searchable-dropdown
  position: relative

  & > input.searchable-dropdown-input
    margin-bottom: 0
    outline: none
    width: 100%
    border-radius: 4px

  .searchable-dropdown-options
    background: $white
    margin: 0
    padding: 0
    text-indent: 0
    list-style-type: none
    position: absolute
    display: block
    left: 0
    top: 40px
    z-index: 10
    max-height: 200px
    width: 100%
    overflow: scroll
    border: 1px solid $border-color
    border-top: 0
    border-radius: 0 0 4px 4px

    &.upwards
      top: auto
      bottom: 38px
      border-radius: 4px 4px 0 0
      border: 1px solid $border-color
      border-bottom: 0

    & > li
      cursor: pointer
      padding: 6px

      &:hover
        background: darken($border-color, .1%)
