.multiple-skus-lineitem-tag
  display: inline-block
  margin: 0 5px 20px 0

  span.lineitem-tag-sku
    background: #f9faff
    padding: 5px
    border: 1px solid darken(#f9faff, 5%)
    border-right: 0
    border-radius: 4px 0 0 4px

    strong
      font-family: $font-bold

  button.lineitem-tag-remove
    background: transparent
    border: none
    color: $red
    background: lighten($red, 25%)
    font-weight: 600
    height: 32px
    border: 1px solid lighten($red, 20%)
    border-radius: 0 4px 4px 0
    padding: 0 8px
