.testsuites-wrapper
  .test-suites-table
    padding: 0

    & > li
      list-style: none

      .test-suite-info
        display: flex

        & > *
          flex: 1

          &:first-child
            flex: 2
          &:last-child
            text-align: right
            margin-right: 10px

      &:first-child
        font-weight: 800
        border-bottom: 1px solid $medium-grey
        padding-bottom: 10px
        margin-bottom: 10px

      &.empty-row
        color: grey

.test-suite-submittion
  background: $white
  position: absolute
