.info-tooltip
  display: inline-block
  position: relative

  &:hover .info-tooltip-text
    display: block

  .info-tooltip-icon
    display: flex
    justify-content: center
    align-items: center
    background: lightblue
    width: 15px
    height: 15px
    border-radius: 50%
    font-weight: 800
    cursor: pointer
    text-transform: none
    margin-left: 5px
    font-style: normal

  .info-tooltip-text
    display: none
    position: absolute
    background: #333
    left: 100%
    top: -2px
    width: 300px
    padding: 10px
    text-align: center
    border-radius: 4px
    z-index: 10
    margin-left: 15px
    color: #fff
    text-transform: none

    &::before
      content: ''
      display: block
      position: absolute
      left: -19px
      top: 1px
      border: 10px solid transparent
      border-right: 10px solid #333
