.drag-and-drop
  background: $light-grey
  padding: $padding*3
  border-radius: 4px
  border: 2px dashed $grey
  text-align: center
  cursor: pointer
  display: flex
  justify-content: center
  flex-direction: column

  i
    font-size: 2.5em
    margin-bottom: $margin

  input[type='file']
    display: none

  &.over
    background: $medium-grey
    border-color: darken($grey, 10%)
