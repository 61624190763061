.menu-dropdown
  overflow: hidden

  .menu-dropdown-title
    display: flex
    justify-content: space-between
    margin-bottom: ($margin / 2)
    cursor: pointer

    h6
      font-weight: 800
      margin: 0

    .menu-dropdown-trigger
      border: none
      width: 6px
      height: 12px
      outline: none
      filter: brightness(30%)
      margin: 3px 3px 0 0
 
  .menu-dropdown-items
    padding: 0 0 0 ($padding / 2)
    list-style: none
    margin: 0

    li
      &:not(:last-child)
        margin-bottom: ($margin / 2)

      a
        color: $black
        text-decoration: none

      span.disabled
        color: $grey

.menu-dropdown.collapsed
  .menu-dropdown-title
    margin-bottom: 1px

  .menu-dropdown-trigger
    transform: rotate(0) !important
    transition: .2s

  .menu-dropdown-items
    display: none
    height: 0

.menu-dropdown.open
  .menu-dropdown-trigger
    transform: rotate(90deg)
    transition: ease-in-out .2s
