.menu
  width: 300px
  height: 100%
  z-index: 12
  position: fixed
  background: linear-gradient(to top right, #253b69, #2a566e, #12272d)
  display: flex
  flex-direction: column
  align-items: center
  padding: $padding

  .menu-brand
    margin-bottom: $margin
    margin-top: -$margin

  .menu-section
    padding: $padding
    width: 100%
    position: relative

    & > *
      z-index: 10
      position: relative
      color: $white
      font-family: 'ArtifaktElement-Bold'

    & h6
      font-family: 'ArtifaktElement-Bold' !important

    div > *
      font-family: 'ArtifaktElement-Light'

    a > span
      color: $white

    &::before
      content: ''
      display: inline-block
      width: 100%
      height: 100%
      border-radius: 4px
      background-color: rgba(255, 255, 255, 0.25)
      position: absolute
      left: 0
      top: 0
      z-index: 5

    .menu-dropdown:not(:last-child)::after
      content: ''
      display: block
      border-bottom: 1px solid rgba(255, 255, 255, 0.5)
      margin-top: $margin
      margin-bottom: $margin

    .menu-dropdown .menu-dropdown::after
      border: none
      margin-top: ($margin / 1.4)
      margin-bottom: 0

    & > a
      color: $black
      font-weight: 800
      text-decoration: none

    &:not(:last-child)
      margin-bottom: ($margin - 10px)

  .menu-links a
    color: #fff

.fake-menu
  width: 100px
  height: 100%
  z-index: 10
  position: fixed
  background: linear-gradient(to right, #35bca9, #0b9ad4)
  padding: $padding

  img
    margin-top: -14px
    margin-left: -14px

  a
    color: $white

.logo
  padding-top: 14px   
  width: 250px
  
