$border-color: #d3d3d3

.status-indicator
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  margin-bottom: 30px

  svg
    margin: 0
    width: 75px
    margin-bottom: $margin

  hgroup
    text-align: center

.status-failed
  color: #ff4c42

.submit-buttons
  display: flex
  justify-content: center
  margin-bottom: 30px

  .button
    background: #109ad0
    color: #fff

    &:not(:last-child)
      margin-right: 5px

.submit-tests-container
  border: 1px solid $border-color
  border-radius: 8px

.submit-test
  padding: 10px

  .name
    margin-right: 10px

  .reason
    color: $grey
    font-style: italic

  .status
    float: right

  &:not(:last-child)
    border-bottom: 1px solid $border-color
