.collapsible-dropdown
  position: relative

  & > span
    display: block
    padding-bottom: ($padding / 2)
    margin-bottom: ($margin / 2)
    border-bottom: 1px solid $grey
    font-weight: 900
    cursor: pointer

  & > img
    width: 7px
    position: absolute
    right: 0
    top: 5px
    transition: all .3s

  .collapsible-dropdown-content
    max-height: 0
    overflow: hidden
    transition: max-height .1s ease-in

  &.open
    .collapsible-dropdown-content
      max-height: 500px
      transition: max-height 1s ease-out

    & > img
      transform: rotate(90deg)
