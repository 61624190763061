.page-title
  display: flex
  justify-content: space-between
  align-items: flex-end
  margin-bottom: $margin

  .title-wrapper
    .title
      margin-bottom: 5px
      font-weight: 600

    .description
      color: $grey
      margin: 0
