.orders-container
  button.orders-test-case
    background: none
    border: none
    padding: 0
    display: flex
    align-items: center
    cursor: pointer
    position: absolute
    left: 10px
    top: 10px

    &:not(:last-child)
      margin-bottom: 15px

.orders-search-filter-actions
  display: flex
  justify-content: space-between

  &.empty
    min-height: 0

.orders-test-cases
  display: flex
  flex-wrap: wrap
  margin-left: -10px
  margin-right: -10px

  .scenario
    flex: 1 0 30%
    margin: 0 10px 20px 10px

    & .test-case
      min-height: 50px

    &.empty
      visibility: hidden
