/* Customize the label (the checkbox-container) */
.checkbox-container
  display: block
  position: relative
  cursor: pointer
  font-size: 22px
  user-select: none

.checkbox-container input
  position: absolute
  opacity: 0
  cursor: pointer
  left: 0
  top: 0
  height: 25px
  width: 25px
  z-index: 2

/* Create a custom checkbox */
.checkbox-checkmark
  position: absolute
  top: 0
  left: 0
  height: 18px
  width: 18px
  background: $white
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid $grey

/* On mouse-over, add a grey background color */
.checkbox-container:hover .checkbox-checkmark
  background: #eee

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-checkmark
  background: $blue
  border-color: $blue

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after
  content: ''
  display: none

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after
  display: block

/* Style the checkmark/indicator */
.checkbox-container .checkbox-checkmark:after
  width: 6px
  height: 12px
  margin-top: -2px
  border: solid $white
  border-width: 0 3px 3px 0
  transform: rotate(45deg)
