.test-suites-wrapper
  display: flex

  .test-suites-services
    margin-right: $margin
    width: 300px
    flex: 0 0 300px

  .test-suites-test-cases
    width: 100%
    display: flex
    flex-flow: column
    justify-content: space-between
    height: calc(100vh - 180px)
    max-height: calc(100vh - 180px)
    overflow: hidden

  ul.category-channel-selection
    padding: 0

    li
      list-style: none
      padding: ($padding / 4) 0

      input[type=checkbox]
        margin-right: 10px

  .test-suites-test-cases
    margin-bottom: $margin

  .test-suite-submittion
    button[disabled]
      background: $blue
