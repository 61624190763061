.collapsable-content
  position: relative
  background: lighten($medium-grey, 5%)
  border-left: 4px solid $medium-grey
  padding: 10px
  border-radius: 4px
  
  &-title
    cursor: pointer
    margin: 0
    font-weight: 400

  &-info
    position: absolute
    top: 12px
    right: 0
    margin-right: 10px
    pointer-events: none
    color: grey

  &-body
    height: 0
    overflow: hidden

    .form-field:last-child
      margin-bottom: 0

  &:not(.open):hover
    background: lighten($medium-grey, 2%)

.collapsable-content.open
  .collapsable-content-body
    height: auto
    margin-top: 20px
    overflow: visible

.collapsable-content:not(:last-child)
  margin-bottom: 10px