.force-center
  display: table
  margin: 0 auto

.AppContent
  margin-left: 50px
  margin-right: 50px
  padding: 0 $margin

// Loading spinner
.loading-spinner, .loading-spinner::before, .loading-spinner::after
  display: inline-block
  height: 25px
  width: 25px

.loading-spinner
  position: relative
  -webkit-animation: rotation 1s infinite linear
  -moz-animation: rotation 1s infinite linear
  -o-animation: rotation 1s infinite linear
  animation: rotation 1s infinite linear

  &::before, &::after
    content: ''
    border-radius: 50%

.loading-spinner::before
  border: 4px solid #f3f3f3

.loading-spinner::after
  border-top: 4px solid #a3a3a3
  border-left: 4px solid transparent
  border-right: 4px solid transparent
  position: absolute
  top: 0
  left: 0

.no-margin
  margin: 0

.no-padding
  padding: 0 !important

@-webkit-keyframes rotation
  from
    -webkit-transform: rotate(0deg)
  to
    -webkit-transform: rotate(359deg)

@-moz-keyframes rotation
  from
    -moz-transform: rotate(0deg)
  to
    -moz-transform: rotate(359deg)

@-o-keyframes rotation
  from
    -o-transform: rotate(0deg)
  to
    -o-transform: rotate(359deg)

@keyframes rotation
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)

