button.button, a.button
  padding: 6px 10px
  border-radius: 4px
  border: 0
  margin: 0
  font-weight: 600
  background: #fff

  &.button-primary
    background: $blue
    color: $white

    &:hover
      background: darken($blue, 5%)
      text-decoration: none

  &.button-link
    background: transparent
    color: $blue

    &.no-spacing
      margin: 0
      padding: 0
      width: 100%

    &:hover
      text-decoration: none

  &.hidden
      visibility: hidden

  &.visible
      visibility: visible   

  &.button-label
    color: $blue
    background: transparent
    padding: 0

  &:not(:last-child)
    margin-right: 5px

  &[disabled]
    background: lighten(silver, 20%)
    color: silver
    cursor: not-allowed

    &:hover
      background: lighten(silver, 20%)
