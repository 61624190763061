.scheduler-modal
  @at-root &__modal-window
    height: auto
    max-width: 600px
    width: 600px
    padding: 16px
    margin: auto
    border-radius: 0
    border: 0
    box-shadow: none
    background: $white
    overflow: visible
    svg
      top: 9px
      left: 5px
      padding: 0!important
      width: 16px
      height: 16px
    label
      font-size: 1rem
  .modal-footer
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    margin-top: 16px
    padding: 16px 0 0 0

.end-date-field
  display: flex
  flex-direction: row
  align-items: baseline
  justify-content: space-between
  width: 190px

.form-group__label
  font-size: 14px !important

.cron-section
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  .form-control--cron
    height: 100px
    display: flex
    flex-direction: column
    justify-content: center
    width: 100%
    margin-right: 8px
    &:last-of-type
      margin-right: 0
