.test-suite-row
  div.test-suite-details
    display: none

  span.test-suite-name
    cursor: pointer

    img.test-suite-shared
      width: 30px
      margin-left: ($margin / 4)

    img.test-suite-expand
      width: 8px
      margin-right: ($margin / 2)

  span.test-suite-actions
    position: relative
    display: flex
    justify-content: flex-end
    button.btn-link-primary
      margin-right: 24px

  &.expanded
    div.test-suite-details
      display: block
      padding-bottom: ($padding / 2)

    span.test-suite-name
      img.test-suite-expand
        transform: rotate(90deg)

  ul.test-suite-row-subcategories
    padding-left: $padding - 4px

    & > li
      list-style-type: none

      span
        font-family: 'ArtifaktElement-Bold'
        font-weight: 900
        display: block

    &:last-child
      margin-bottom: -10px

  ul.test-suite-row-testcases
    padding-left: 0

    & > li
      list-style: none
      display: flex
      justify-content: space-between
      align-items: center
      height: 30px

      &:hover
        background: $light-grey

      &:last-child
        margin-bottom: ($margin / 2)

      &:not(:last-child)
        border-bottom: 1px solid $medium-grey
        padding-bottom: 4px
        margin-bottom: 4px

[class*="bi-"]::before
        vertical-align: middle
[class*="bi-three-dots"]::before
        vertical-align: text-bottom
        color: black
