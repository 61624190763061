.test-selection-container
  background: $medium-grey
  padding: ($padding / 2)
  padding-bottom: $padding
  display: flex
  gap: ($margin / 2)
  height: 100%

  & > section
    flex: 1
    background: $white
    padding: ($padding / 2)
    overflow: scroll
    position: relative

  .selected-test-cases > ul.test-selection-testcases
    height: calc(100% - 280px)

  .test-suite-submittion
    color: $white
    padding: ($padding / 4)
    position: absolute
    bottom: 0px
    width: calc(100% - 25px) // hack

    .submittion-count
      color: $grey
      border-top: 1px solid $grey
      text-align: center
      background: lime
      position: relative
      margin-bottom: 10px

      & > span
        display: block
        position: absolute
        left: 0
        right: 0
        margin: auto
        background: $white
        width: 180px
        margin-top: -12px
        text-align: center

    .submittion-actions
      display: flex

    button
      width: 100%
      border-radius: 0
      height: 50px

      small
        display: block

      &:hover
        background: $blue

.test-selection-testcases
  padding: 0
  overflow: scroll

  h6
    font-weight: 800
    margin-bottom: ($margin / 2)

  li.testcase
    list-style: none
    display: flex
    justify-content: space-between
    align-items: center
    padding: ($padding / 4) 0

    strong
      font-weight: 800
      font-size: .8em
      margin-right: ($margin / 4)
      color: $grey

    button
      min-width: 105px

    &:not(:last-child)
      border-bottom: 1px solid $medium-grey

  &.marbin-fix
    padding-bottom: 120px !important

  &-empty
    text-align: center
    margin-top: $margin
    color: $grey
