.cjt-wrapper
  .cjt-search-filter-actions
    display: flex
    justify-content: space-between
    min-height: 32px

  .cjt-actions-select-all
    display: inline-block
    margin-right: $margin

    .cjt-actions-select-all-label
      margin-left: 25px
      font-weight: 400

.scenarios
  display: flex
  flex-wrap: wrap
  margin-left: -10px
  margin-right: -10px

  .scenario
    flex: 1 0 30%
    margin: 0 10px 20px 10px

    & .test-case
      min-height: 80px

    &.empty
      visibility: hidden
