//Fonts
$base: 'ArtifaktElement', sans-serif
$font-bold: 'ArtifaktElement-Bold'
$font-regular: 'ArtifaktElement-Reg'
$font-light: 'ArtifaktElement-Light'

//Colors
$white: #fff
$black: #232323
$grey: #979797
$medium-grey: #efefef
$light-grey: #fafafa
$accent: #0696d7
$turquoise: #33bcad
$blue: #5f60ff
$darker-blue: darken(#5f60ff, 10%)
$green: #82bc48
$red: #f44236
$yellow: #f5c22b
$bs1: rgba(0, 0, 0, 0.63) 0px 0px 43px -13px

$border-color: #ced4da

// margins and paddings
$margin: 25px
$padding: 25px

//Transitions
$easing: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s
$easing-slow: cubic-bezier(0.075, 0.82, 0.165, 1) 0.6s
