html
  font-size: 14px

body
  font-family: 'ArtifaktElement-Light', Arial, Helvetica, sans-serif !important

*
  outline: none

  a
    color: $blue