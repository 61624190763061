.Sidenav
  &__logout
    background-color: transparent
    border: none
    padding: 0
    i
      color: $white
      margin-left: 0
    span
      color: $white
      transform: translateX(-70px)

  &__logout:hover > i
    color: red
  &__logut:hover > span
    color: red
