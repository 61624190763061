.screenshot-body
  margin: 50px
.screenshot
  border: 1px solid lightgray
  padding: 8px
  margin: 5px
  margin-top: 20px
  margin-bottom: 20px
  img
    max-width: 100%
    max-height: 100%
    min-width: 100%