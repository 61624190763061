.jobs-queue-wrapper
  margin-bottom: $margin

  .checkbox-wrapper, .checkbox-container
    width: 100%
    height: 100%

  .checkbox-wrapper .checkbox-container
    display: flex
    align-items: center
    justify-content: center

    .checkbox-checkmark
      position: static

  .search-filter
    margin-bottom: $margin / 2

.table-filters
  display: flex
  align-items: flex-end

  & > div
    margin-right: $margin
    margin-bottom: 0

  label
    font-family: $font-light
    text-transform: uppercase

.table-legend
  float: left
  margin-top: 5px

.status
  display: inline-block
  width: 10px
  height: 10px
  margin-right: 5px

  &:not(:first-child)
    margin-left: 5px

.table-loading-placeholder
  padding: $padding
  border-top: 1px solid #f2f2f2
  display: flex
  justify-content: center

.job-status
  text-transform: capitalize

  &.success::before, &.completed::before, &.passed::before
    background: $green

  &.failed::before
    background: $red

  &::before
    content: ''
    width: 10px
    height: 10px
    border-radius: 50%
    display: inline-block
    background: $grey
    margin-right: 5px

.job-actions
  width: 100%
  display: flex
  justify-content: center

section.jobs-queue-table
  margin-bottom: $margin

  &:last-child, &:last-child table
    margin-bottom: 0

  h4
    font-weight: 600

  table tr th, table tr td
    width: 40%
    word-break: break-all
    font-weight: 400
    padding-left: ($padding / 2)
    padding-right: ($padding / 2)

// react-table overrides
.ReactTable
  border: 1px solid #ced4da
  border-radius: 4px

.rt-thead
  z-index: 10

.rt-th
  color: #333
  text-transform: uppercase
  margin: 0
  display: flex
  justify-content: center
  align-items: center

  & > div
    padding: 5px 0

.-darker
  .rt-th
    background: darken($medium-grey, 5%)
  .rt-tr
    background: $medium-grey

.executions-table-count
  display: flex
  justify-content: center

.count
  display: flex
  justify-content: center
  align-items: center
  width: 20px
  height: 20px
  border-radius: 50%
  font-weight: 700

  &:not(:last-child)
    margin-right: $margin / 2

.count, .status
  color: #333 !important

  &.status-queued
    background: $medium-grey

  &.status-in-progress
    background: $yellow

  &.status-failed, &.status-aborted
    background: $red

  &.status-success, &.status-completed
    background: $green

  &.status-resubmitted
    background: $blue

.project-icon
  vertical-align: super

.jobs-queue-list
  padding: 0
  margin: 0

  li
    list-style: none
.attachments
  display: flex
  flex-direction: column
  align-items: flex-start
  .row
    margin-left: 1px
    display: flex
    flex-direction: row

.btn-refresh
  padding-top: 0
  padding-bottom: 0

// Variables
$arrow-size: 6px
$arrow-color: #000
$arrow-transition-duration: 0.3s

.step-details-section
  width: 100%
  background: $medium-grey
  table
    width: 100%
  thead
    background: lightgray

.hide
  display: none
.show
  display: revert

.step-view-button
  display: flex

// Styles
div.step-toggle
  position: relative
  margin-right: 5px
  padding-right: 15px

  &:before
    content: ''
    position: absolute
    top: 50%
    left: 5%
    transform: translate(-50%, -50%) rotate(-90deg)
    //width: 0
    //height: 0
    border-style: solid
    border-width: $arrow-size $arrow-size 0 $arrow-size
    border-color: $arrow-color transparent transparent transparent
    transition: transform $arrow-transition-duration

  &.open:before
    transform: translate(-50%, -50%) rotate(0deg)

  &.disable:before
    border-color: lightgrey transparent transparent transparent
    cursor: not-allowed

.enable
  cursor: pointer

.transaction-body
  margin: 50px
