.lightbox
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, .5)
  position: absolute
  z-index: 100
  display: flex
  align-items: center
  justify-content: center

  .lightbox-content
    background: $medium-grey
    padding: $padding
    border-radius: 4px
    border: 1px solid $grey
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    & > span, & > p
      margin: 0

    & > div
      margin-bottom: $margin
