.home-wrapper
  margin-bottom: 50px

  .home-intro
    display: flex
    flex-direction: column
    margin-bottom: $margin
    background: #f4f4f4
    padding: $margin
    border-radius: 4px
    color: #485366
    font-family: $font-bold

    .home-intro-icon
      width: 80px
      margin-bottom: ($margin / 2)

    h3
      margin-bottom: $margin

    p
      font-size: 1.25rem

  .home-offerings
    color: #fff
    text-align: center

    ul
      display: flex
      padding: 0
      text-align: left

      li
        padding: 20px
        background: #fff
        color: #333
        flex: 0 1 33.3%
        list-style: none
        border-radius: 8px
        border: 1px solid #c8c8c8
        text-align: left
        display: flex
        flex-direction: column

        .svg-wrapper
          margin-bottom: ($margin / 2)
          text-align: center

        h5
          margin-bottom: 10px
          font-weight: 600
          font-family: $font-bold

        &:not(:last-child)
          margin-right: 20px

        a
          text-align: left
          font-family: $font-bold
          color: $black
          margin-right: $margin
          display: flex

        .anchor-caret
          width: 15px
          height: 15px
          display: flex
          justify-content: center
          align-items: center
          background: $black
          color: $white
          border-radius: 50%
          font-style: normal
          margin-left: ($margin / 4)
          margin-top: 3px