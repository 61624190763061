.notification-field-checkbox-group
  display: flex

.notification-field-checkbox
  display: flex
  margin-right: 20px

  input
    margin-right: 5px

.form-field

    input
      width: auto
      margin: 4px 4px 0 0


