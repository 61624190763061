.test-case
  background: $light-grey
  padding: 10px
  display: flex
  position: relative

  &.disabled
    .test-case-body-label, .test-case-body-description, a
      color: lighten($grey, 15%)

    button.radio-button, span.checkbox-checkmark, .checkbox-container input
      cursor: not-allowed

    span.checkbox-checkmark
      border-color: lighten($grey, 25%)

.test-case-body
  margin-left: 25px

.test-case-body-label
  margin: 0
  cursor: pointer

.test-case-body-description
  & > p
    color: $grey
    margin: 0
    font-size: 0.9rem

.test-case button.button-label
  display: block

button.radio-button
  display: flex
  justify-content: center
  align-items: center
  width: 18px
  height: 18px
  border: 1px solid $grey
  border-radius: 50%
  transition: all .4s
  background: $light-grey
  position: absolute

  &.checked
    background: $green
    box-shadow: 0 0 0 3px $light-grey inset

.test-case-actions
  position: absolute
  right: 0
  top: 0

  & > button
    background: transparent
    border: none
    font-size: 1.25rem
    color: $grey

  .dropdown-menu
    border-radius: 0
    padding: 0
    min-width: 0
