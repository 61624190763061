.btn-primary
  background: $blue
  border: none

.btn-link
  color: $blue
  background: transparent !important

  &:hover
    background: darken($blue, 5%)
    color: $darker-blue

.form-group > label
  text-transform: uppercase
  font-size: .9em
  font-family: $font-light

.toast
  opacity: 1

ul > li
  list-style: none

table.table
  th, td
    padding-left: 0

  th
    border-top: 0

  td.empty-row
    color: $grey
