.form-group
  &.has-error > input
    border-color: $red

  small.has-error-reason
    color: $red

  .mandatory
    color: $red

.file-note
  font-size: 10px
  color: lightslategray
  margin-top: 3px
  font-style: italic
.note
  font-style: italic
  font-size: 12px
  strong
    font-weight: bolder

div.has-error-reason
  color: $red
  font-size: 12px
  margin-top: -12px

div.form-text-description
  color: #6c757d
  font-size: 12px
  margin-top: -12px
