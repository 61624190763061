.loading-container
  width: 100vw
  height: 100vh
  display: flex
  align-items: center
  flex-direction: column
  padding: ($padding * 2)

  .loading-spinner
    margin-bottom: ($margin / 2)

  .loading-message
    color: $grey
