div.searchable-dropdown-wrapper
  display: flex
  align-items: flex-end

  label
    text-transform: uppercase
    display: block
    font-family: ArtifaktElement-Light

    span
      color: $grey
      font-size: .75em
  
  button.button
    width: 100%
    height: 43px

  &.has-error
    input:first-child
      border-color: $red

    small.has-error-reason
      color: $red
      position: absolute
      bottom: -15px
      left: 15px

.searchable-dropdown-quantity-input
  padding: 10px
  width: 100%
