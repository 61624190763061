@font-face {
  font-family: 'ArtifaktElement-Bold';
  src: url("https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Bold.eot");
  /* IE9 Compat Modes */
  src: url("https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Bold.eot?#iefix") format("embedded-opentype"), url("https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Bold.woff2") format("woff2"), url("https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Bold.woff") format("woff"), url("https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Bold.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'ArtifaktElement-Reg';
  src: url("https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Book.eot");
  /* IE9 Compat Modes */
  src: url("https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Book.eot?#iefix") format("embedded-opentype"), url("https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Book.woff2") format("woff2"), url("https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Book.woff") format("woff"), url("https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Book.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'ArtifaktElement-Light';
  src: url("https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Light.eot");
  /* IE9 Compat Modes */
  src: url("https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Light.eot?#iefix") format("embedded-opentype"), url("https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Light.woff2") format("woff2"), url("https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Light.woff") format("woff"), url("https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Light.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: 300;
  font-style: normal; }