section.channels
  margin-bottom: $margin / 2
  border-bottom: 1px solid $grey
  position: relative
  display: flex
  align-items: baseline
  .dropdown-menu
    border-radius: 0
    padding: 0
    min-width: 0
    transform: translate3d(16px, 24px, 0px) !important
    .dropdown-item
      padding: .50rem 1.5rem
      margin: 0
      border: none

  .channel-wrapper
    display: flex
    align-items: baseline

    button
      &.secondary-action-button
        color: $blue
        padding: 0
        margin-left: -10px

    &.active
      border-bottom: 4px solid $blue !important

    &.disabled
      color: $grey

    &:hover
      border-bottom: 4px solid $medium-grey

  button
    background: $white
    border: none
    border-bottom: 4px solid $white
    padding: 0 15px 10px 15px

    &.action-button
      color: $blue
[class*=" bi-three-dots-vertical"] 
      line-height: 0
      vertical-align: middle
