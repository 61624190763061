header.heading-wrapper
  display: flex
  justify-content: flex-end
  margin: $margin 0
  
  button.button-link, a.button-link
    color: $grey
    font-weight: 100

  *:not(:last-child)::after
    content: ''
    display: inline-block
    border-right: 1px solid $grey
    height: 10px
    margin-left: $margin


section.section-title
  display: flex
  justify-content: space-between
  align-items: flex-end
  margin-bottom: 1rem

div.section-title-group
  display: flex
  align-items: flex-end

h2.title
  margin: 0 .5rem 0 0
