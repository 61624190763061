.products-test-cases
  display: flex
  flex-wrap: wrap
  margin-left: -10px
  margin-right: -10px

  .scenario
    flex: 1 0 30%
    margin: 0 10px 20px 10px

    & .test-case
      min-height: 60px

    &.empty
      visibility: hidden

.products-modal-form
  display: flex
  justify-content: space-between

  & > .products-modal-form-field
    width: 100%
    margin-bottom: 0

    &:not(:last-child)
      margin-right: 15px

.products-table
  thead th
    color: $grey
    border: none
    font-weight: 600

  tbody tr:first-child td
    border: none

  .products-table-option td
    position: relative

    &:first-child
      padding-left: 25px

    & > button
      left: 0

    .products-table-option-term
      text-transform: lowercase
