.list-input-wrapper
  display: flex

  *:not(:last-child)
    margin-right: 10px
  
.list-input-values
  display: flex
  flex-wrap: wrap
  margin-top: 10px

  .list-input-element
    white-space: nowrap
    margin-bottom: 10px

    strong
      overflow: hidden
      display: inline-block
      border-radius: 4px 0 0 4px
      white-space: nowrap
      padding: 0 4px
      border: 1px solid #ced4da
      border-right: 0
      vertical-align: top

    button
      background: #ff7979
      border-radius: 0 3px 3px 0
      border: 1px solid #eb4d4b
      width: 10px
      height: 23px
      line-height: 0
      padding: 0 10px

      i
        margin-left: -7px

    input
      border: none
      width: auto
      height: 20px

    &:not(:last-child)
      margin-right: 10px

  &.disabled
    .list-input-element
      strong
        border-radius: 4px
        border: 1px solid darken(#f9faff, 10%)
