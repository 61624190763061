.notifications
  position: absolute
  right: 75px

.toast
  animation: fadeIn 1s
  position: relative
  min-width: 350px
  z-index: 1000000

  .toast.success
    animation: fadeOut 5s forwards

  &.success i, &.danger i
    position: absolute
    top: -6px
    left: 6px
    font-size: 2em

  &.success
    border-color: $green

    .toast-header
      border-color: $green

    i
      color: $green

  &.danger
    border-color: $red

    .toast-header
      border-color: $red

    i
      color: $red
      top: -7px

  .toast-body > p
    margin: 0

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes fadeOut
  0%
    opacity: 1
  90%
    opacity: 1
  100%
    opacity: 0
    display: none
