.loading-spinner-wrapper
  display: inline-flex
  align-items: center

  span.loading-spinner
    margin-right: 10px

    &, &::before, &::after
      width: 22px
      height: 22px
