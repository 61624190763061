.search-header
  display: flex
  align-items: flex-end
  padding-bottom: 10px
  section
    margin-right: 16px

.btn-search
  height: calc(1.5em + 0.75rem + 2px)
  &:disabled
    cursor: not-allowed

.store-header
  display: flex
  align-items: center
  justify-content: space-between
  margin-top: 16px
  margin-bottom: 16px

.store-title
  font-size: 24px
  font-weight: bold
  color: #333
  margin-bottom: 10px
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2)

.no-data
  font-size: 16px
  font-weight: normal
  color: #666
  margin-top: 10px

.light-title
  font-size: 24px
  font-weight: bold
  color: #333
  margin-bottom: 10px

.table-container
  margin-bottom: 1rem

.spacer
  height: 32px

.show-pointer
  cursor: pointer !important
.expanded__content
  margin-top: 16px
  margin-bottom: 16px
  margin-left: 16px
  max-width: 300px
.btn-column
  width: 100%
